import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  getUsersReport,
} from "../../redux/actions/authAction";

const UsersReport = () => {
  const dispatch = useDispatch();
  const today = new Date()
  const { allOrganizations } = useSelector(
    (state) => state.getOrganizationData
  );
  const { usersReportData } = useSelector(
    (state) => state.getReportsData
  );
  const [organizationId, setOrganizationId] = useState(
    allOrganizations?.data?.[0]?._id
  );
  
  const formatDate = (dateString) => {
    const createdAt = new Date(dateString);
    const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };

    const date = `${createdAt.toLocaleDateString('en-US', optionsDate)}`

    return date === 'Invalid Date' ? 'No changes made yet' : date;
  };

  useEffect(() => {
    if (organizationId) {
      dispatch(getUsersReport({ organizationId }));
    }
  }, [dispatch, organizationId]);

  return (
    <>
      <>
        <ToastContainer />
      </>
      <div className="manage-user">
        <div className="row mb-3 pt-5 pl-5">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex">
              Users Report
            </h1>
          </div>
        </div>
        <div className="streak-report-main">
          {allOrganizations?.data?.length ? (
            <>
              <div className="organization-report-filter">
                <Form.Label className="d-flex organization-report-filter-heading">
                  Organizations
                </Form.Label>
                <Form.Select
                  onChange={(e) => setOrganizationId(e.target.value)}
                  aria-label="Default select example"
                  value={organizationId}
                >
                  {allOrganizations?.data?.map((organization, index) => {
                    return (
                      <option value={organization?._id}>
                        {organization?.organizationName}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
              <div className="report-engagement-heading">
                <p>Daily Report ({formatDate(today)})</p>
              </div>
              <div className="organization-report-page mt-5">
                <>
                  <div className="user-report-table">
                    {usersReportData?.data?.length !== 0 ? (
                      <>
                        <table>
                          <thead>
                            <tr
                              className="header-row"
                              style={{ margin: "10px", height: "100px" }}
                            >
                              <th
                                style={{
                                  width: "800px",
                                  textAlign: "center",
                                  position: "static",
                                }}
                              >
                                User Name
                              </th>
                              <th
                                style={{
                                  width: "1500px",
                                  textAlign: "center",
                                  position: "static",
                                  padding: "5px"
                                }}
                              >
                                User Email
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  minWidth: "100px",
                                  position: "static",
                                }}
                              >
                                Streak Value
                              </th>
                  
                              <th
                                style={{
                                  textAlign: "center",
                                  width: "2000px",
                                  position: "static",
                                }}
                              >
                                Awareness Content
                              </th> 
                              <th
                                style={{
                                  textAlign: "center",
                                  width: "1600px",
                                  position: "static",
                                }}
                              >
                                Activity Content 
                              </th> 
                              <th
                                style={{
                                  textAlign: "center",
                                  width: "1600px",
                                  position: "static",
                                }}
                              >
                                Total Content 
                              </th> 
                              <th
                                style={{
                                  textAlign: "center",
                                  width: "1600px",
                                  position: "static",
                                }}
                              >
                                Date of last streak change
                              </th> 
                              <th
                                style={{
                                  textAlign: "center",
                                  width: "1000px",
                                  position: "static",
                                }}
                              >
                                Back up Streak (value from the last change)
                              </th> 
                            </tr>
                          </thead>

                          <>
                          <tbody>
                            {usersReportData?.data?.map(
                              (data, index) => {
                                return (
                                  <>
                                    <tr
                                      className="whole-row-client"
                                      style={{
                                        borderBottom: "1px solid #f1f5f9",
                                      }}
                                    >
                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div style={{ color: "blue" }}>
                                            {data?.userName}
                                          </div>
                                        </div>
                                      </td>
                                      
                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div style={{ color: "blue" }}>
                                            {data?.userEmail}                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div style={{ color: "blue" }}>
                                            {data?.streakValue}
                                          </div>
                                        </div>
                                      </td>

                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            textAlign: "center",
                                            wordBreak: "break-word",
                                            paddingTop: "8%"
                                          }}
                                      
                                        >
                                          <div style={{ color: "blue", width: "100%" }}>
                                            <p style={{ fontSize: 14 }}>{data?.awareness.available} Available</p>
                                            <p style={{ fontSize: 14 }}>{data?.awareness.engaged} Opened</p>
                                            <p style={{ fontSize: 14 }}>{data?.awareness.percentage}% Engagement</p>
                                          </div>
                                        </div>
                                      </td>


                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            textAlign: "center",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div style={{ color: "blue", width: "100%" }}>
                                          <p style={{ fontSize: 14 }}>{data?.activity.available} Available</p>
                                            <p style={{ fontSize: 14 }}>{data?.activity.engaged} Completed</p>
                                            <p style={{ fontSize: 14 }}>{data?.activity.percentage}% Engagement</p>
                                          </div>
                                        </div>
                                      </td>


                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            textAlign: "center",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div style={{ color: "blue", width: "100%" }}>
                                          <p style={{ fontSize: 14 }}>{data?.totalContent.available} Available</p>
                                            <p style={{ fontSize: 14 }}>{data?.totalContent.engaged} Opened</p>
                                            <p style={{ fontSize: 14 }}>{data?.totalContent.percentage}% Engagement</p>
                                          </div>
                                        </div>
                                      </td>

                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            // textAlign: "left",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div style={{ color: "blue" }}>
                                            {formatDate(data?.streakLastUpdated)}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="d-grid gap-2 d-md-block"
                                          style={{
                                            // textAlign: "left",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <div style={{ color: "blue" }}>
                                            {data?.streakValueOnLastChange}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                          </tbody>
                          </>
                        </table>
                      </>
                    ) : (
                      <h3>No Active Contest</h3>
                    )}
                  </div>
                </>
              </div>
            </>
          ) : (
            <div className="No-data-statement">
              {" "}
              <h3>No Organizations yet</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UsersReport;